import { DBConfig } from 'ngx-indexed-db';

const DB_NAME = 'NG_DECLARANT';

const VERSION = 1;
const KEY_PATH = 'updated';
export const NOMENCLATURE_STORE_NAME = 'NOMENCLATURE';
export const INDEXED_DB_CONFIG: DBConfig = {
  name: DB_NAME,
  version: VERSION,
  objectStoresMeta: [
    {
      store: NOMENCLATURE_STORE_NAME,
      storeConfig: { keyPath: KEY_PATH, autoIncrement: false },
      storeSchema: [
        // {
        //   name: 'value',
        //   keypath: 'value',
        //   options: {
        //     unique: false,
        //   },
        // },
      ],
    },
  ],
};
